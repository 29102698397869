<template>
    <div class="wrapper">
        <router-view name="navbar" class="mb-4"></router-view>
        <router-view name="sidebar"></router-view>
        <!-- Content Wrapper. Contains page content -->
        <div class="content-wrapper p-2">
            <!-- <router-view name="header"></router-view> -->
            <!-- Main content -->
            <section class="content">
                <router-view></router-view>
            </section>
        </div>
        <!-- /.content-wrapper -->
        <router-view name="footer"></router-view>
    </div>
</template>

<script>
export default {
    mounted() {
        $('body,html').removeAttr('style')

         window.addEventListener('offline', (event) => {
            this.$swal({
                title: 'Error',
                text: "No Internet Connection",
                icon: 'error',
                showConfirmButton:false,
                showCancelButton: false,
                allowOutsideClick: false,
                allowEscapeKey: false,
            })
        });

        window.addEventListener('online', (event) => {
            this.$swal.close()
        });

    }
}
</script>
